import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: palette.background.default,
    boxShadow: '0 32px 34px 0 rgba(51, 51, 51, 0.1)',
    borderRadius: spacing(0, 5),
    padding: '80px 100px',
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },
}))

export default useStyles
