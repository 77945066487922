import React from 'react'

import {textBoxPropTypes} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import Section from 'components/UI/Section'

import useStyles from './styles'

const TextBox = ({title, description}) => {
  const classes = useStyles()

  return (
    <Section hasPaddingTop={false}>
      <div className={classes.container}>
        <ContentSection
          title={title}
          variation="No Image"
          description={description}
        />
      </div>
    </Section>
  )
}

TextBox.propTypes = textBoxPropTypes

TextBox.defaultProps = {
  description: {
    raw: '',
  },
  title: '',
}

export default TextBox
