import * as R from 'ramda'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React from 'react'

import {corporateGovernancePropTypes} from 'helpers/propTypes'
import Hero from 'components/UI/Hero'
import JobSummary from 'components/AboutUs/CorporateGovernance/JobSummary'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import TextBox from 'components/AboutUs/CorporateGovernance/TextBox'

const CorporateGovernance = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    metaTitle,
    metaDescription,
    imageOrVideo,
    pageTitle,
    shortDescription: {shortDescription},
    surtitle,
    boardOfDirectorsTitle,
    boardOfDirectorsContent,
    auditCommitteeTitle,
    auditCommitteeContent,
    managementTeams,
  } = R.pathOr(null, ['allContentfulCorporateGovernance', 'nodes', 0], data)

  const managers = R.pathOr(null, [0, 'managers'], managementTeams)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration
            webSiteName="keyrus"
            color="red"
            right={0}
            bottom={-160}
          />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>

        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          {pageTitle && (
            <Hero
              hat={surtitle}
              title={pageTitle}
              description={shortDescription}
              media={imageOrVideo}
            />
          )}
        </Section>
      </div>
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <TextBox
          title={boardOfDirectorsTitle}
          description={boardOfDirectorsContent}
        />
      </Section>
      <JobSummary managers={managers} />
      <Section hasVerySmallPadding hasPaddingBottom={false}>
        <TextBox
          title={auditCommitteeTitle}
          description={auditCommitteeContent}
        />
      </Section>
    </>
  )
}

CorporateGovernance.propTypes = corporateGovernancePropTypes

export const pageQuery = graphql`
  query templateCorporateGovernance(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulCorporateGovernance(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        metaTitle
        metaDescription {
          metaDescription
        }
        pageTitle
        shortDescription {
          shortDescription
        }
        surtitle
        imageOrVideo {
          file {
            contentType
            url
          }
        }
        boardOfDirectorsTitle
        boardOfDirectorsContent {
          raw
        }
        auditCommitteeContent {
          raw
        }
        auditCommitteeTitle
        managementTeams {
          managers {
            biography {
              raw
            }
            jobTitle
            linkedInProfile
            name
            photo {
              file {
                contentType
                url
              }
            }
          }
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default CorporateGovernance
